import { css } from "aphrodite";
import { useCallback, useMemo } from "react";
import { useLocation } from "react-router-dom";

import StandardButton from "components/Buttons/StandardButton";

import authActions from "actions/auth";

import useActionCreators from "hooks/useActionCreators";
import { useLoggedIn } from "hooks/useLoggedInUser";
import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  homepageMobileHeader: {
    maxWidth: "33.33%",
    width: "100%",
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
    padding: "2.125rem .5rem 2rem",
    color: colours.white,

    [ScreenSizes.mdAndAbove]: {
      maxWidth: "30rem",
    },
  },
  searchContainer: {
    margin: "1.625rem 0 1.75rem",
  },
  title: {
    ...gStyles.avalonBold,
    fontSize: "1.75rem",
    margin: "0 0 1rem",
    textAlign: "center",
    [ScreenSizes.mdAndAbove]: {
      fontSize: "2rem",
    },
  },
  titleLoggedIn: {},
  subtitle: {
    ...gStyles.fontRegular,
    color: colours.white,
    fontSize: "1rem",
    lineHeight: 1.75,
    textAlign: "center",
    margin: 0,
    marginBottom: "2rem",
    [ScreenSizes.mdAndAbove]: {
      fontSize: "1.25rem",
    },
  },
  subtitleLoggedIn: {
    marginBottom: 0,
  },
  link: {
    ...gStyles.avalonBold,
  },
  buttons: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    width: "100%",
    marginBottom: ".5rem",
  },
  buttonContainer: {
    display: "flex",
    flex: 1,
    maxWidth: "8rem",
  },
  buttonSpacer: {
    width: "1rem",
    height: "100%",
  },
};

const buttonStyles = {
  button: {
    fontSize: ".75rem",
    padding: ".4rem 1rem",
  },
};

const HomepageMobileHeader = (props) => {
  const { styles } = useStyles(baseStyles, props);
  const isLoggedIn = useLoggedIn();

  const location = useLocation();

  const { startAuthFlow } = useActionCreators({
    startAuthFlow: authActions.startAuthFlow,
  });

  const handleLogin = useCallback(
    () =>
      startAuthFlow(true, {
        analyticsVariables: {
          triggered_by: "HomepageMobileHeaderLoginButton",
        },
      }),
    [startAuthFlow]
  );
  const handleRegister = useCallback(
    () =>
      startAuthFlow(false, {
        analyticsVariables: {
          triggered_by: "HomepageMobileHeaderRegisterButton",
        },
      }),
    [startAuthFlow]
  );

  const isOnMyPopularTab = useMemo(() => location.pathname === "/", [location]);
  const HeadingComponent = isOnMyPopularTab ? "h1" : "h3";

  const renderAuthButtons = () => (
    <div className={css(styles.buttons)}>
      <div className={css(styles.buttonContainer)}>
        <StandardButton
          label="Log In"
          variation="white"
          customStyles={buttonStyles}
          onClick={handleLogin}
          flat
          rounded
        />
      </div>
      <div className={css(styles.buttonSpacer)} />
      <div className={css(styles.buttonContainer)}>
        <StandardButton
          label="Register"
          variation="pink"
          customStyles={buttonStyles}
          onClick={handleRegister}
          flat
          rounded
        />
      </div>
    </div>
  );

  return (
    <div className={css(styles.homepageMobileHeader)}>
      <HeadingComponent
        className={css(styles.title, isLoggedIn && styles.titleLoggedIn)}
      >
        Your Daily Source for Podcast Discovery
      </HeadingComponent>
      <p
        className={css(styles.subtitle, isLoggedIn && styles.subtitleLoggedIn)}
      >
        Uncover the latest Podcasts, Ratings & Reviews, Guest Credits, Curated
        Lists, and more!
      </p>
      {!isLoggedIn && renderAuthButtons()}
    </div>
  );
};

export default HomepageMobileHeader;
