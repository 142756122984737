import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "aphrodite";
import PropTypes from "prop-types";
import { memo, Fragment, useCallback, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

import ContentContainer from "components/Common/Layout/ContentContainer";
import Steps from "components/Common/Steps";
import Tabs from "components/Common/Tabs";

import HomepageDesktopHeader from "./HomepageDesktopHeader";
import HomepageMobileHeader from "./HomepageMobileHeader";
import HomepageSidebar from "./HomepageSidebar/HomepageSidebarAsync";

import sendGAEvent from "utils/sendGAEvent";

import useLoggedInUser from "hooks/useLoggedInUser";
import { useStyles } from "hooks/useStyles";
import useWindowSize from "hooks/useWindowSize";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import PageStyles from "styles/PageStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  ...PageStyles,
  headingSpacerContainer: {
    width: "100%",
    paddingBottom: "4rem",
    backgroundColor: colours.white,
    borderBottom: "4px #eaedf3 solid",

    [ScreenSizes.lgAndAbove]: {
      backgroundColor: "transparent",
      paddingBottom: 0,
      borderBottom: "none",
    },
  },
  headingSpacer: {
    ...gStyles.gradientBackground,
    height: "auto",
    width: "280%",
    marginLeft: "-90%",
    overflow: "hidden",

    [ScreenSizes.lgAndAbove]: {
      paddingTop: 0,
      paddingBottom: 0,
      width: "118%",
      marginLeft: 0,
    },
  },
  headingSpacerFix: {
    // This fixes the width of the header container so it's back to ~100%
    width: `${(100 / 118) * 100}%`,
  },
  contentContainer: {
    marginTop: "-4rem",
    [ScreenSizes.lgAndAbove]: {
      marginTop: 0,
    },
  },
  mainContent: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    maxWidth: "100%",
  },
  headerTabsContainer: {
    display: "flex",
    flexDirection: "row",
    [ScreenSizes.lgAndAbove]: {
      position: "absolute",
      bottom: "100%",
    },
  },
  headerTab: {
    display: "flex",
    flexDirection: "column",
    height: "3.5rem",
    minWidth: "10.5rem",
    backgroundColor: "#fff",
    color: colours.oldSecondary,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    padding: ".75rem 1.5rem 0.5rem 1rem",
    marginRight: "1rem",
    opacity: ".6",
    transition: "opacity 300ms",
    cursor: "pointer",
    position: "relative",

    [ScreenSizes.lgAndAbove]: {
      height: "auto",
    },
  },
  currentTab: {
    opacity: 1,
    boxShadow: "0px 0px 14px -14px rgba(0, 0, 0, 0.07)",
  },
  headerMobileTab: {
    ...gStyles.fontRegular,
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    height: "calc(4rem - 4px)", // offset for border
    fontSize: 15,
    cursor: "pointer",
    maxWidth: "8rem",
    marginLeft: "calc(100% / 8)",
    position: "relative",

    ":first-child": {
      marginLeft: 0,
    },
    [ScreenSizes.smAndBelow]: {
      width: "auto",
    },
  },
  headerMobileTabTitle: {
    ...gStyles.fontRegular,
    paddingBottom: 15,
    color: colours.oldSecondary,
    borderBottom: "4px transparent solid",
  },
  currentMobileTabTitle: {
    ...gStyles.fontBold,
    color: "#000",
    borderBottom: `4px ${colours.primary} solid`,
  },
  headerTabName: {
    ...gStyles.avalonBold,
    display: "block",
    fontSize: "1.125rem",
    margin: 0,
  },
  headerTabDescription: {
    ...gStyles.fontRegular,
    display: "block",
    fontSize: 13,
    marginTop: ".25rem",
  },
  columnContainer: {
    ...PageStyles.columnContainer,
    paddingTop: "1.625rem",
    background: "#eaedef",

    [ScreenSizes.lgAndAbove]: {
      ...PageStyles.columnContainer[ScreenSizes.lgAndAbove],
      background: "none",
      paddingTop: "3.75rem",
    },
  },
  mobileContainer: {
    display: "block",

    [ScreenSizes.lgAndAbove]: {
      display: "none",
    },
  },
  desktopContainer: {
    display: "none",

    [ScreenSizes.lgAndAbove]: {
      display: "block",
    },
  },
};

const getTabTo = (tab, location) => ({
  pathname: tab.root ? "/" : tab.key,
  state: {
    preserveScroll: true,
    ...((location && location.state) || {}),
  },
});

const STEPS_STYLES = {
  step: {
    maxWidth: "100%",
    minWidth: "100%",
    minHeight: 0,
  },
};

const LoggedOutHomepage = (props) => {
  const { tabs, tabProps: passedTabProps, renderFeed } = props;
  const { styles } = useStyles(baseStyles, props);

  const user = useLoggedInUser();

  const location = useLocation();

  const { isWindowSizeOrLess } = useWindowSize();
  const mobile = isWindowSizeOrLess("medium");

  useEffect(() => {
    sendGAEvent({
      action: "abTestingEvent",
      page: "OldLoggedOutHomepage",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderTab = useCallback(
    (tabProps) => (tab) => {
      const isCurrentTab = tabProps.currentTab.key === tab.key;

      return (
        <Link
          key={tab.key}
          className={css(styles.headerTab, isCurrentTab && styles.currentTab)}
          onClick={tab?.onClick ? tab.onClick : null}
          to={tab?.key && getTabTo(tab, location)}
          data-testid={`${tab?.key || "_"}_${tab?.title}`}
        >
          <h2 className={css(styles.headerTabName)}>{tab.name}</h2>
          <span className={css(styles.headerTabDescription)}>
            {tab.description}
          </span>
        </Link>
      );
    },
    [
      location,
      styles.headerTabDescription,
      styles.headerTabName,
      styles.headerTab,
      styles.currentTab,
    ]
  );

  const renderMobileTab = useCallback(
    (tabProps) => (tab) => {
      const isCurrentTab = tabProps.currentTab.key === tab.key;

      return (
        <Link
          key={tab.key}
          className={css(
            tab.sidebar ? styles.headerMobileTabSidebar : styles.headerMobileTab
          )}
          data-testid={`${tab?.key || "_"}_${tab?.title}`}
          onClick={tab?.onClick ? tab.onClick : null}
          to={tab?.key && getTabTo(tab, location)}
        >
          <div
            className={css(
              styles.headerMobileTabTitle,
              isCurrentTab && styles.currentMobileTabTitle
            )}
          >
            {(tab.icon && <FontAwesomeIcon icon={tab.icon} />) || tab.name}
          </div>
        </Link>
      );
    },
    [styles, location]
  );

  const renderLayout = useCallback(
    (tabProps) => {
      const renderContent = (stepProps = null) => (
        <div className={css(styles.mainContent)}>
          <div
            className={css(
              styles.headerTabsContainer,
              mobile && styles.headerTabsContainerMobile
            )}
          >
            {tabs.map(mobile ? renderMobileTab(tabProps) : renderTab(tabProps))}
          </div>
          <div className={css(styles.columnContainer)}>
            <div className={css(styles.mainColumn)}>
              {stepProps
                ? stepProps.renderStep(renderFeed(tabProps.currentTab))
                : renderFeed(tabProps.currentTab)}
            </div>
            <div className={css(styles.desktopContainer)}>
              <HomepageSidebar user={user} mobile={mobile} />
            </div>
          </div>
        </div>
      );

      if (mobile) {
        return (
          <Steps
            steps={tabs}
            renderSteps={renderContent}
            styles={STEPS_STYLES}
          />
        );
      }

      return <div className={css(styles.mainContent)}>{renderContent()}</div>;
    },
    [
      mobile,
      styles.mainContent,
      styles.headerTabsContainer,
      styles.headerTabsContainerMobile,
      styles.columnContainer,
      styles.mainColumn,
      styles.desktopContainer,
      tabs,
      renderMobileTab,
      renderTab,
      renderFeed,
      user,
    ]
  );

  return (
    <Fragment>
      <div className={css(styles.headingSpacerContainer)}>
        <div className={css(styles.headingSpacer)}>
          <div className={css(styles.mobileContainer)}>
            <HomepageMobileHeader />
          </div>
          <div
            className={css(styles.headingSpacerFix, styles.desktopContainer)}
          >
            <HomepageDesktopHeader user={user} />
          </div>
        </div>
      </div>
      <ContentContainer
        style={styles.contentContainer}
        noVerticalPadding
        noHorizontalPadding={mobile}
        fullWidth={mobile}
      >
        <Tabs
          tabs={tabs}
          renderLayout={renderLayout}
          tabProps={passedTabProps}
          byRoute
          customTabs
        />
      </ContentContainer>
    </Fragment>
  );
};

LoggedOutHomepage.propTypes = {
  tabs: PropTypes.array.isRequired,
  tabProps: PropTypes.object,
  renderFeed: PropTypes.func.isRequired,
};

LoggedOutHomepage.defaultProps = {
  tabProps: {},
};

export default memo(LoggedOutHomepage);
