import { css } from "aphrodite";
import { List, isImmutable } from "immutable";
import PropTypes from "prop-types";
import { memo, useCallback, useEffect, useMemo } from "react";
import { useSwipeable } from "react-swipeable";

import gStyles from "../../styles/GenericStyles";
import { toPascalCase } from "../../utils/case";

import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";

const PAGINATION_HEIGHT = ".75rem";

const baseStyles = {
  stepsContainer: {
    width: "100%",
    minHeight: "100%",
    overflowX: "hidden",
  },
  stepsContainerWithPagination: {
    paddingBottom: PAGINATION_HEIGHT,
    position: "relative",
  },
  steps: {
    display: "flex",
    flexDirection: "row",
    position: "relative",
  },
  step: {
    maxWidth: "100%",
    minWidth: "100%",
    minHeight: "100%",
  },

  stepPaginationContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    height: PAGINATION_HEIGHT,
    marginTop: "2rem",
  },
  stepPaginationSelector: {
    ...gStyles.resetButton,
    width: ".75rem",
    height: ".75rem",
    borderRadius: "50%",
    marginLeft: "1rem",
    backgroundColor: colours.greyishBlue,
    transition: "400ms background-color",
    cursor: "pointer",

    ":first-child": {
      marginLeft: 0,
    },
  },
  stepPaginationSelectorCurrent: {
    backgroundColor: colours.oldSecondary,
  },
};

const Steps = (props) => {
  const {
    transitionPeriod,
    slideTo,
    renderSteps,
    renderStep: passedRenderStep,
    steps,
    onSwipeHandlers: passedOnSwipeHandlers,
    currentStep,
    currentStepIndex,
    contain,
    setStepIndex,
    dataId,
    onStepChange,
  } = props;
  const { styles } = useStyles(baseStyles, props);
  const hasCurrentStep =
    currentStep !== undefined || currentStepIndex !== undefined;

  const handleSetStepIndex = useCallback(
    (stage, index) => () => setStepIndex(index, stage),
    [setStepIndex]
  );

  const swipeHandlers = useSwipeable({
    ...passedOnSwipeHandlers,
    trackTouch: true,
  });

  useEffect(() => {
    if (onStepChange) {
      onStepChange(
        currentStepIndex,
        isImmutable(steps)
          ? steps.get(currentStepIndex)
          : steps[currentStepIndex]
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStepIndex]);

  const renderPaginationSelector = (stage, index) => (
    <button
      key={index}
      className={css(
        styles.stepPaginationSelector,
        index === currentStepIndex && styles.stepPaginationSelectorCurrent
      )}
      onClick={handleSetStepIndex(stage, index)}
    />
  );

  const renderStepPagination = () => (
    <div className={css(styles.stepPaginationContainer)}>
      {steps.map(renderPaginationSelector)}
    </div>
  );

  const renderStep = (step, index) => {
    let content = null;

    if (!hasCurrentStep || currentStepIndex === index) {
      if (currentStep) {
        content = currentStep;
      } else {
        content = passedRenderStep ? passedRenderStep(step, index) : step;
      }
    }

    const name = typeof step === "string" ? toPascalCase(step) : null;

    return (
      <div
        key={index}
        className={css(
          styles.step,
          styles[`stepIndex${index}`],
          styles[`stepName${name || "Unknown"}`]
        )}
      >
        {content}
      </div>
    );
  };

  const style = useMemo(
    () => ({
      transition: `all ${transitionPeriod}ms`,
      [slideTo]: `${-(hasCurrentStep ? currentStepIndex : steps - 1) * 100}%`,
    }),
    [transitionPeriod, slideTo, steps, hasCurrentStep, currentStepIndex]
  );

  if (contain) {
    const renderContainStep = (step, index) => {
      const containKey = `container${(step && step.key) || index}`;

      return (
        <div key={containKey} className={css(styles.stepsContainer)}>
          <div className={css(styles.steps)} style={style} {...swipeHandlers}>
            {renderStep(step, index)}
          </div>
        </div>
      );
    };

    return (
      <div className={css(styles.steps)} {...swipeHandlers}>
        {renderSteps({
          renderStep: renderContainStep,
        })}
      </div>
    );
  }

  return (
    <div
      data-id={dataId}
      className={css(
        styles.stepsContainer,
        setStepIndex && styles.stepsContainerWithPagination
      )}
    >
      <div className={css(styles.steps)} style={style} {...swipeHandlers}>
        {renderSteps ? renderSteps({ renderStep }) : steps.map(renderStep)}
      </div>
      {setStepIndex &&
        (steps.length || steps.size) > 1 &&
        renderStepPagination()}
    </div>
  );
};

Steps.propTypes = {
  dataId: PropTypes.string,
  renderStep: PropTypes.func,
  renderSteps: PropTypes.func,
  setStepIndex: PropTypes.func,
  steps: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.array,
    PropTypes.instanceOf(List),
  ]),
  transitionPeriod: PropTypes.number,
  slideTo: PropTypes.string,
  onSwipeHandlers: PropTypes.object,
  currentStep: PropTypes.node,
  currentStepIndex: PropTypes.number,
  contain: PropTypes.bool,
  onStepChange: PropTypes.func,
};

Steps.defaultProps = {
  dataId: null,
  renderStep: null,
  renderSteps: null,
  setStepIndex: null,
  steps: 0,
  transitionPeriod: 400,
  slideTo: "left",
  onSwipeHandlers: {},
  currentStep: undefined,
  currentStepIndex: undefined,
  contain: false,
  onStepChange: undefined,
};

export default memo(Steps);
