import { css } from "aphrodite";
import PropTypes from "prop-types";
import { memo, Fragment, useCallback, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

import ContentContainer from "components/Common/Layout/ContentContainer";
import Tabs from "components/Common/Tabs";

import HomepageSidebar from "./HomepageSidebar/HomepageSidebarAsync";
import NewHomepageDesktopHeader from "./NewHomepageDesktopHeader";

import sendGAEvent from "utils/sendGAEvent";

import useLoggedInUser from "hooks/useLoggedInUser";
import { useStyles } from "hooks/useStyles";
import useWindowSize from "hooks/useWindowSize";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  contentContainer: {
    marginTop: "-4rem",
    [ScreenSizes.lgAndAbove]: {
      marginTop: 0,
    },
  },
  newMainColumn: {
    flex: 2,
    display: "inline-block",

    [ScreenSizes.lgAndAbove]: {
      width: "calc(100% - 250px)",
    },

    [ScreenSizes.xlAndAbove]: {
      width: "calc(100% - 300px)",
      paddingRight: "5%",
    },
  },
  newContentContainer: {
    display: "flex",
    background: "#eaedef",

    [ScreenSizes.lgAndAbove]: {
      background: colours.white,
      paddingTop: "1.75rem",
    },
  },
  tabContainer: {
    ...gStyles.avalonBold,
    color: colours.black,

    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",

    cursor: "pointer",
    padding: "0.25rem 0 0",
    margin: "0.25rem 0.75rem",

    borderBottom: "3px transparent solid",
    fontSize: "1.5rem",

    ":focus": {
      borderBottomColor: "rgba(0,0,0,0.1)",
    },
  },
  tabsContainer: {
    display: "flex",
    flexDirection: "row",
    marginBottom: "1rem",

    background: colours.white,
    padding: "0.5rem 0",
  },

  currentPageTab: {
    borderColor: colours.primary,
    ":focus": {
      borderColor: colours.primaryLighter,
    },
  },
  sideBarContainer: {
    display: "none",

    [ScreenSizes.lgAndAbove]: {
      display: "inline-block",
    },
  },
};

const getTabTo = (tab, location) => ({
  pathname: tab.root ? "/" : tab.key,
  state: {
    preserveScroll: true,
    ...((location && location.state) || {}),
  },
});

const NewLoggedOutHomepage = (props) => {
  const { tabs, tabProps: passedTabProps, renderFeed } = props;
  const { styles } = useStyles(baseStyles, props);

  const user = useLoggedInUser();

  const location = useLocation();

  const { isWindowSizeOrLess } = useWindowSize();
  const mobile = isWindowSizeOrLess("medium");

  useEffect(() => {
    sendGAEvent({
      action: "abTestingEvent",
      page: "NewLoggedOutHomepage",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderLayout = useCallback(
    (tabProps) => {
      return (
        <div className={css(styles.newContentContainer)}>
          <div className={css(styles.newMainColumn)}>
            <div className={css(styles.tabsContainer)}>
              {tabs &&
                tabs.map((tab) => {
                  const isCurrentTab = tabProps.currentTab.key === tab.key;
                  return (
                    <Link
                      key={tab.key}
                      className={css(
                        styles.tabContainer,
                        isCurrentTab && styles.currentPageTab
                      )}
                      onClick={tab?.onClick ? tab.onClick : null}
                      to={getTabTo(tab, location)}
                    >
                      {tab.name}
                    </Link>
                  );
                })}
            </div>
            {renderFeed(tabProps.currentTab)}
          </div>
          <div className={css(styles.sideBarContainer)}>
            <HomepageSidebar user={user} mobile={mobile} />
          </div>
        </div>
      );
    },
    [
      mobile,
      styles.newContentContainer,
      styles.newMainColumn,
      styles.tabsContainer,
      styles.sideBarContainer,
      styles.tabContainer,
      styles.currentPageTab,
      tabs,
      renderFeed,
      user,
      location,
    ]
  );

  return (
    <Fragment>
      <NewHomepageDesktopHeader user={user} />
      <ContentContainer
        noVerticalPadding
        noHorizontalPadding={mobile}
        fullWidth={mobile}
      >
        <Tabs
          tabs={tabs}
          renderLayout={renderLayout}
          tabProps={passedTabProps}
          byRoute
          customTabs
        />
      </ContentContainer>
    </Fragment>
  );
};

NewLoggedOutHomepage.propTypes = {
  tabs: PropTypes.array.isRequired,
  tabProps: PropTypes.object,
  renderFeed: PropTypes.func.isRequired,
};

NewLoggedOutHomepage.defaultProps = {
  tabProps: {},
};

export default memo(NewLoggedOutHomepage);
