import { css } from "aphrodite";
import { Link } from "react-router-dom";

import { TABS_KEYS } from "./NewHomepageDesktopHeader";

import { useStyles } from "hooks/useStyles";

import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  categories: {
    margin: 0,
    minHeight: "1.25rem",
    fontSize: "0.875rem",
    lineHeight: "1rem",
  },
  categoriesItem: {
    ...gStyles.avalonBold,
    ":hover": {
      textDecoration: "underline",
    },
    ":focus": {
      textDecoration: "underline",
    },
  },
  hideMobileAndTablet: {
    display: "none",

    [ScreenSizes.lgAndAbove]: {
      display: "inline-block",
    },
  },
};

const NewHomepageDesktopHeaderSubTitle = (props) => {
  const { tab } = props;
  const { styles } = useStyles(baseStyles, props);

  if (tab === TABS_KEYS.ALL) {
    return (
      <p className={css(styles.categories)}>
        Try:{" "}
        <Link
          to="/categories/true-crime-category/podcasts"
          className={css(styles.categoriesItem)}
        >
          True Crime
        </Link>
        ,{" "}
        <Link
          to="/search/all/q/joe%20rogan"
          className={css(styles.categoriesItem)}
        >
          Joe Rogan
        </Link>
        <span className={css(styles.hideMobileAndTablet)}>
          ,{" "}
          <Link
            to="/podcasts/radiolab-207202/insights"
            className={css(styles.categoriesItem)}
          >
            Radiolab
          </Link>
          ,{" "}
          <Link
            to="/categories/business/podcasts"
            className={css(styles.categoriesItem)}
          >
            Business
          </Link>
          ,{" "}
          <Link to="/search/all/q/obama" className={css(styles.categoriesItem)}>
            Obama
          </Link>
        </span>
      </p>
    );
  }

  if (tab === TABS_KEYS.PODCASTS) {
    return (
      <p className={css(styles.categories)}>
        Try:{" "}
        <Link
          to="/podcasts/the-diary-of-a-ceo-with-steven-1021523"
          className={css(styles.categoriesItem)}
        >
          Diary of a CEO
        </Link>
        ,{" "}
        <Link
          to="/categories/fitness/podcasts"
          className={css(styles.categoriesItem)}
        >
          Fitness Podcasts
        </Link>
        <span className={css(styles.hideMobileAndTablet)}>
          ,{" "}
          <Link
            to="/podcasts/dr-death-716956"
            className={css(styles.categoriesItem)}
          >
            Dr. Death
          </Link>
          ,{" "}
          <Link
            to="/podcasts/what-now-with-trevor-noah-5506937"
            className={css(styles.categoriesItem)}
          >
            What Now? With Trevor Noah
          </Link>
        </span>
      </p>
    );
  }

  if (tab === TABS_KEYS.EPISODES) {
    return (
      <p className={css(styles.categories)}>
        Try:{" "}
        <Link
          to="/search/episodes/q/%22taylor%20swift%22$%C2%A3*%5E?sort=new"
          className={css(styles.categoriesItem)}
        >
          Taylor Swift
        </Link>
        ,{" "}
        <Link
          to="/search/episodes/q/%22best%20of%22?sort=ranking"
          className={css(styles.categoriesItem)}
        >
          Best Of
        </Link>
        <span className={css(styles.hideMobileAndTablet)}>
          ,{" "}
          <Link
            to="/creators/whitney-wolfe-herd-107Zzpv8Oz/appearances"
            className={css(styles.categoriesItem)}
          >
            Featuring Whitney Herd
          </Link>
          ,{" "}
          <Link
            to="/search/all/q/best%20selling%20authors"
            className={css(styles.categoriesItem)}
          >
            Best Selling Authors
          </Link>
          ,{" "}
          <Link
            to="/categories/business/episodes/new"
            className={css(styles.categoriesItem)}
          >
            New in Business
          </Link>
        </span>
      </p>
    );
  }

  if (tab === TABS_KEYS.CREDITS) {
    return (
      <p className={css(styles.categories)}>
        Try:{" "}
        <Link
          to="/creators/conan-obrien-107Zzon0vn"
          className={css(styles.categoriesItem)}
        >
          Conan O'brien
        </Link>
        ,{" "}
        <Link
          to="/creators/hank-green-107ZzkEssr"
          className={css(styles.categoriesItem)}
        >
          Hank Green
        </Link>
        <span className={css(styles.hideMobileAndTablet)}>
          ,{" "}
          <Link
            to="/creators/hank-green-107ZzkEssr"
            className={css(styles.categoriesItem)}
          >
            Mel Robbins
          </Link>
          ,{" "}
          <Link
            to="/creators/malcolm-gladwell-107ZzkE3bY"
            className={css(styles.categoriesItem)}
          >
            Malcolm Gladwell
          </Link>
          ,{" "}
          <Link
            to="/creators/amanda-gorman-107aRxubpu"
            className={css(styles.categoriesItem)}
          >
            Amanda Gorman
          </Link>
        </span>
      </p>
    );
  }

  if (tab === TABS_KEYS.LISTS) {
    return (
      <p className={css(styles.categories)}>
        Try:{" "}
        <Link
          to="/lists/best-parenting-podcasts-of-2022-107a4WbkuG"
          className={css(styles.categoriesItem)}
        >
          Top Parenting Podcasts
        </Link>
        ,{" "}
        <Link
          to="/search/lists/q/audio%20drama$£*%5E?sort=ranking"
          className={css(styles.categoriesItem)}
        >
          Audiodrama
        </Link>
        <span className={css(styles.hideMobileAndTablet)}>
          ,{" "}
          <Link
            to="/lists/the-ultimate-list-of-true-crime-taboo-subjects-paranormal-107aDrdG42"
            className={css(styles.categoriesItem)}
          >
            Ultimate True Crime List
          </Link>
          ,{" "}
          <Link
            to="/lists/high-quality-history-podcasts-107ZzlNpR5"
            className={css(styles.categoriesItem)}
          >
            High-Quality History
          </Link>
        </span>
      </p>
    );
  }

  if (tab === TABS_KEYS.BRANDS) {
    return null;
  }

  return <div className={css(styles.categories)}></div>;
};

export default NewHomepageDesktopHeaderSubTitle;
