import { css } from "aphrodite";
import { memo, useMemo } from "react";
import { useLocation } from "react-router-dom";

import ContentContainer from "components/Common/Layout/ContentContainer";

import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";

const baseStyles = {
  contentContainer: {},
  homepageDesktopHeader: {
    color: colours.white,
    padding: "2rem 20rem 7rem 0",
  },
  svg: {
    position: "absolute",
    bottom: 0,
    right: "1rem",
    height: "70%",
    width: "auto",
  },
  title: {
    ...gStyles.avalonSemiBold,
    fontSize: "2.25rem",
    margin: "1.75rem 0 1rem",
  },
  subtitle: {
    ...gStyles.fontRegular,
    margin: 0,
    fontSize: "1rem",
    lineHeight: 1.5,
  },
  strong: {
    ...gStyles.fontSemiBold,
  },
};

const HomepageDesktopHeader = (props) => {
  const { styles } = useStyles(baseStyles, props);
  const location = useLocation();

  const isOnMyPopularTab = useMemo(() => location.pathname === "/", [location]);
  const HeadingComponent = isOnMyPopularTab ? "h1" : "h3";

  return (
    <ContentContainer
      style={styles.contentContainer}
      noVerticalPadding
      siteHeaderPadding
    >
      <div className={css(styles.homepageDesktopHeader)}>
        <div>
          <img
            className={css(styles.svg)}
            src={"/images/podcast-listening-couple-desaturated.svg"}
            width={284}
            height={224}
            alt={""}
            title={""}
          />
          <HeadingComponent className={css(styles.title)}>
            Your Daily Source for Podcast Discovery
          </HeadingComponent>
          <p className={css(styles.subtitle)}>
            Uncover the latest Podcasts, Ratings & Reviews, Guest Credits,
            Curated Lists, and more!
          </p>
        </div>
      </div>
    </ContentContainer>
  );
};

export default memo(HomepageDesktopHeader);
