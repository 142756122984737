import { useEffect, useState, useMemo } from "react";
import { useHistory } from "react-router-dom";

import {
  useAbTestingContext,
  setCookieIfNoExists,
  AB_TESTING_EXPERIMENTS,
  AB_TESTING_VERSIONS,
} from "containers/AbTesting/AbTestingProvider";

import LoggedOutHomepage from "./LoggedOutHomepage";
import NewLoggedOutHomepage from "./NewLoggedOutHomepage";

import sendGAEvent from "utils/sendGAEvent";

import useQuery from "hooks/useQuery";

const LoggedOutHomepageContainer = (props) => {
  const { tabs, tabProps, renderFeed } = props;
  const { isNewHomePageSearch } = useAbTestingContext();

  const [query, setQuery] = useQuery();

  const version = query?.version;

  const [showNewVersion] = useState(
    version &&
      (version === "NEW_HOMEPAGE_SEARCH" ||
        version === "NEW_HOMEPAGE_SEARCH_ANALYTICS")
  );

  const history = useHistory();
  const isClient = !!window;

  useEffect(() => {
    if (isClient && version && version.length > 0 && query) {
      const newQuery = { ...query };

      delete newQuery.version;

      setQuery(newQuery);
    }
  }, [history, isClient, query, setQuery, version]);

  useEffect(() => {
    if (isClient && version && version.length > 0 && query && showNewVersion) {
      setCookieIfNoExists(AB_TESTING_EXPERIMENTS.HOMEPAGE_SEARCH);
    }
  }, [isClient, query, showNewVersion, version]);

  useEffect(() => {
    if (!isClient) {
      return;
    }

    if (version === "NEW_HOMEPAGE_SEARCH_ANALYTICS") {
      sendGAEvent({
        action: "abTestingNewRegistry",
        context: "HomePage",
        page: "NewLoggedOutHomepage",
        abTestingName: AB_TESTING_EXPERIMENTS.HOMEPAGE_SEARCH,
        version: AB_TESTING_VERSIONS.TEST,
      });
    } else if (version === "OLD_HOMEPAGE_SEARCH_ANALYTICS") {
      sendGAEvent({
        action: "abTestingNewRegistry",
        context: "HomePage",
        page: "NewLoggedOutHomepage",
        abTestingName: AB_TESTING_EXPERIMENTS.HOMEPAGE_SEARCH,
        version: AB_TESTING_VERSIONS.CONTROL,
      });
    }
  }, [isClient, version]);

  if (showNewVersion || isNewHomePageSearch) {
    return (
      <NewLoggedOutHomepage
        tabs={tabs}
        tabProps={tabProps}
        renderFeed={renderFeed}
      />
    );
  }

  return (
    <LoggedOutHomepage
      tabs={tabs}
      tabProps={tabProps}
      renderFeed={renderFeed}
    />
  );
};

export default LoggedOutHomepageContainer;
